* {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
}
html {
      font-family: "Montserrat", sans-serif;
}
body {
      background: #eee;
      padding: 0 16px;
}